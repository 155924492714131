import {
  CalendarIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import request from 'graphql-request'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function () {
  const [meetings, setMeetings] = useState(null);
  
  useEffect(() => {
    const fetchMeetings = async () => {
      const { posts } = await request( process.env.REACT_APP_GRAPHQL_REQUEST, `
        {
          posts(first: 15, , where: {event: true}, orderBy: date_DESC) {
            id
            title
            date
            event
            location
            coverImage {
              id
              url
            }
            author {
              id
              picture {
                fileName
                url
              }
            }
          }
        }
      `);
      setMeetings(posts);
      console.log(meetings)
    }
    fetchMeetings()
  }, [])
  return (
    <div className="relative h-full bg-white pt-16 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">ADRA' <span className="text-teal-600">Event</span></h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Vous voulez participer activement à la vie de l'association?
          </p>
        </div>
        { !meetings ? (<p className="mt-3 text-base text-gray-500">À venir...</p>) : (<ol className="max-h-96 overflow-hidden overflow-y-auto mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          { meetings.map((meeting) => (
            <li key={meeting.id} className="relative flex space-x-6 py-6 xl:static">
              <img src={meeting?.coverImage?.url} alt="" className="h-14 w-14 flex-none rounded-full" />
              <div className="flex-auto">
                <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{meeting.title}</h3>
                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                  <div className="flex items-start space-x-3">
                    <dt className="mt-0.5">
                      <span className="sr-only">Date</span>
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>
                      <time>
                        {meeting.date}
                      </time>
                    </dd>
                  </div>
                  <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                    <dt className="mt-0.5">
                      <span className="sr-only">Location</span>
                      <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>{meeting.location}</dd>
                  </div>
                </dl>
              </div>
            </li>
          ))}
        </ol>)}
      </div>
    </div>
  )
}