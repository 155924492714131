import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon
} from '@heroicons/react/outline'
import { Fragment } from 'react'

export default function Navbar () {
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
 return (
   <Popover className="relative bg-white shadow">
     <div className="max-w-7xl mx-auto px-4 sm:px-6">
       <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
         <div className="flex justify-start lg:w-0 lg:flex-1">
           <a href="/">
             <span className="sr-only">ADRA Evry</span>
             <img
               className="h-8 w-auto sm:h-10"
               src="https://media.graphassets.com/iC5LToRFRGt14N4tBIAz"
               alt="Logo de l'association ADRA Evry"
             />
           </a>
         </div>
         {/*<div className="-mr-2 -my-2 md:hidden">
           <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
             <span className="sr-only">Open menu</span>
             <MenuIcon className="h-6 w-6" aria-hidden="true" />
           </Popover.Button>
         </div>
         <Popover.Group as="nav" className="hidden md:flex space-x-10">
           <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
             À Propos
           </a>
           <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
             ADRA' <span className="text-teal-600">Actu</span>
           </a>
           <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
             Nous Contacter
           </a>
         </Popover.Group>*/}
         <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
           <a
             href="https://www.helloasso.com/associations/adrafrance/formulaires/3"
             target="_blank"
             rel="noreferrer"
             className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-600 hover:bg-teal-700"
           >
             Faire un don
           </a>
         </div>
       </div>
     </div>
  
     {/*<Transition
       as={Fragment}
       enter="duration-200 ease-out"
       enterFrom="opacity-0 scale-95"
       enterTo="opacity-100 scale-100"
       leave="duration-100 ease-in"
       leaveFrom="opacity-100 scale-100"
       leaveTo="opacity-0 scale-95"
     >
       <Popover.Panel
         focus
         className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
       >
         <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
           <div className="pt-5 pb-6 px-5">
             <div className="flex items-center justify-between">
               <div>
                 <a href="/">
                   <span className="sr-only">ADRA Evry</span>
                   <img
                     className="h-8 w-auto"
                     src="https://media.graphassets.com/iC5LToRFRGt14N4tBIAz"
                     alt="Logo de l'association humanitaire ADRA Evry"
                   />
                 </a>
               </div>
               <div className="-mr-2">
                 <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                   <span className="sr-only">Close menu</span>
                   <XIcon className="h-6 w-6" aria-hidden="true" />
                 </Popover.Button>
               </div>
             </div>
             <div className="mt-6">
               <nav className="grid gap-y-8">
                 <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                   À Propos
                 </a>
                 <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                   ADRA' <span className="text-teal-600">Actu</span>
                 </a>
                 <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                   Nous Contacter
                 </a>
               </nav>
             </div>
           </div>
           <div className="py-6 px-5 space-y-6">
             <div>
               <a
                 href="https://www.helloasso.com/associations/adrafrance/formulaires/3"
                 target="_blank"
                 rel="noreferrer"
                 className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-600 hover:bg-teal-700"
               >
                 Faire un don
               </a>
             </div>
           </div>
         </div>
       </Popover.Panel>
     </Transition> */}
   </Popover>
 )
}