/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react'
import request from 'graphql-request'

export default function RecentsPosts() {
  const [posts, setPosts] = useState(null);
  
  useEffect(() => {
    const fetchRecentsPosts = async () => {
      const { posts } = await request( process.env.REACT_APP_GRAPHQL_REQUEST, `
        {
          posts(first: 3, orderBy: date_DESC) {
            id
            title
            tags
            slug
            date
            publishedAt
            createdAt
            coverImage {
              id
              fileName
              url
            }
            excerpt
            author {
              id
              name
              picture {
                fileName
                url
              }
            }
          }
        }
      `);
      setPosts(posts);
    }
    fetchRecentsPosts()
  }, [])
  
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">ADRA' <span className="text-teal-600">Actu</span></h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Retrouvez toute notre actualité
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          { !posts ? (
            <p className="mt-3 text-base text-gray-500">À venir...</p>
          ) : ( posts.map((post) => (
            <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post?.coverImage?.url} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  {
                    post.tags && post.tags.length > 0 ? post.tags.map( tag => (
                    <p className="text-sm font-medium text-teal-600 inline   mr-2 mb-2 rounded-2xl">
                      <a href="#" className="hover:underline">
                        {tag}
                      </a>
                    </p>
                    )) : null
                  }
                  <a href="#" className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.excerpt}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href="#">
                      <span className="sr-only">{post?.author?.name}</span>
                      <img className="h-10 w-10 rounded-full" src={post?.author?.picture.url} alt={post?.author?.name} />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href="#" className="hover:underline">
                        {post?.publishedBy?.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <span>Publié le: </span>
                      <time>{post.date}</time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )))}
        </div>
      </div>
    </div>
  )
}
